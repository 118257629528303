.credit-container {
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2c3531;
    object-fit: contain;
    z-index: 1000;
  }

  .credit-center-container {
    width: 100%;
    height: inherit;
    max-width: 1100px;
    padding-top: 40px;
    padding-bottom: 20px;
    z-index: inherit;
    position: relative;
    text-align: center;
    color: var(--paragraph-color);
    font-size: 15px;
  }