.project-container {
    height: fit-content;
    min-height: 800px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2c3531;
    object-fit: contain;
    z-index: 1000;
  }

  .project-center-container {
    width: 90%;
    height: inherit;
    max-width: 1100px;
    margin-top: 100px;
    z-index: inherit;
  }
  
  .project-h1 {
    margin-top: 0px;
    margin-bottom: 30px;
    font-family: 'Source Code Pro', monospace;
    color: #ffcb9a;
    font-size: var(--title-font-size);
    position: relative;
    width: max-content;
  }
  .project-h1::before,
  .project-h1::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .project-h1::before {
    background: var(--background-color);
    animation: typewriter var(--typewriter-speed) steps(20) forwards;
  }
  .project-h1::after {
    width: 0.05em;
    background: #ffcb9a;
    animation: 
      typewriter var(--typewriter-speed) steps(20) forwards,
      blink 1s step-start infinite;
  }

  .project-table {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* grid-template-rows: repeat(auto-fit, 400px); */
    height: px;
    gap: 15px;
    padding: 0;
    list-style-type: none;
  }
  .project-card {
    position: relative;
    display: block;
    height: 100%;  
    border-radius: 15px;
    overflow: hidden;
    text-decoration: none;

  }

  .project-card-logo-wrapper {
    z-index: inherit;
    position: relative;
    z-index: 0;
  }
  .project-card-logo {
    z-index: -1;
    width: 100%;
    height: 300px;
    object-fit:cover;
    filter: grayscale(100%) contrast(120%);
  }
  .project-card-logo-wrapper::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #4fdbf3;
    opacity: 0.5;
    z-index: 1;
  }
  .project-card:hover .project-card-logo-wrapper::before {
    opacity: 0;
    transition: 0.2s ease-in-out;
  }
  .project-card:hover .project-card-logo {
    filter: none;
    transition: 0.2s ease-in-out;
  }

  .project-card-overlay {
    z-index: 100;
    position: absolute;
    color: #fff;
    background-color: #323d38;
    opacity: 0.9;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;      
    height: 300px;
    transform: translateY(65%);
    transition: .2s ease-in-out;
    padding: 20px;
  }
  .project-card-header {
    z-index: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    font-size: 23px;
    padding-bottom: 10px;
  }
  .project-card-button-github {
    color: white;
    text-decoration: none;
  }
  .project-card-button-github:hover {
    color: #ffcb9a;
  }
  .project-card-name {
    z-index: inherit;
    font-family: 'Source Code Pro', monospace;
  }
  .project-card:hover .project-card-overlay {
    transform: translateY(15%);
  }
  .project-card-tech {
    z-index: inherit;
    font-size: 14px;
    color: #c7c7c7;
    padding-bottom: 30px;
  }
  .project-card-description {
    font-size: 15px;
    color: #c7c7c7;
  }
  
  @media screen and (max-width: 400px) {
    .project-h1 {
        font-size: 25px;
    }
    .project-card-overlay {
        background-color: #323d38;
        transform: translateY(10%);
        opacity: 0.90;
    }
    .project-card-logo-wrapper::before {
        opacity: 0;
    }
    .project-card-logo {
        filter: none;
    }
  }



 
  
 
  